const getEVMJavascriptSnippet = (
  chain: string
) => `  import Picket from "@picketapi/picket-js";
  const picket = new Picket("PUBLISHABLE_KEY");

  await picket.login({
    contractAddress: "CONTRACT_ADDRESS",
    minTokenBalance: 1,
    chain: "${chain}"
  });
`;

const getEVMShellSnippet = (
  chain: string
) => `$ curl https://picketapi.com/api/v1/auth
    -X POST
    -u PROJECT_SECRET_KEY
    -H 'Content-Type: application/json'
    -d '{
      "walletAddress": "0xWALLET_ADDRESS",
      "signature": "SUPER_SECRET_SIGNATURE",
      "contractAddress": "CONTRACT_ADDRESS",
      "minTokenBalance": 1,
      "chain": "${chain}"
    }'`;

const getEVMSwiftSnippet = (chain: string) => `  // In app delegate
  import Picket
  let picket = Picket(key: "PUBLISHABLE_KEY")

  // In View Controller
  picket.Login(
    contractAddress: "CONTRACT_ADDRESS",
    minTokenBalance: 1,
    chain: "${chain}"
  )`;

const snippets = {
  javascript_ethereum: `  import Picket from "@picketapi/picket-js";
  const picket = new Picket("PUBLISHABLE_KEY");

  await picket.login({
    contractAddress: "CONTRACT_ADDRESS",
    minTokenBalance: 1
  });
  `,
  javascript_no_import: `  
  await picket.login({
    contractAddress: "CONTRACT_ADDRESS",
    minTokenBalance: 1
});`,
  javascript_polygon: getEVMJavascriptSnippet("polygon"),
  javascript_solana: `  import Picket from "@picketapi/picket-js";
  const picket = new Picket("PUBLISHABLE_KEY");

  await picket.login({
    tokenIds: ["TOKEN_ID_1", "TOKEN_ID_2"],
    minTokenBalance: 1,
    chain: "solana"
  });`,
  javascript_avalanche: getEVMJavascriptSnippet("avalanche"),
  javascript_flow: getEVMJavascriptSnippet("flow"),
  javascript_optimism: getEVMJavascriptSnippet("optimism"),
  javascript_arbitrum: getEVMJavascriptSnippet("arbitrum"),
  shell_ethereum: `$ curl https://picketapi.com/api/v1/auth
    -X POST
    -u PROJECT_SECRET_KEY
    -H 'Content-Type: application/json'
    -d '{
      "walletAddress": "0xWALLET_ADDRESS",
      "signature": "SUPER_SECRET_SIGNATURE",
      "contractAddress": "CONTRACT_ADDRESS",
      "minTokenBalance": 1
    }'`,
  shell_solana: `$ curl https://picketapi.com/api/v1/auth
    -X POST
    -u PROJECT_SECRET_KEY
    -H 'Content-Type: application/json'
    -d '{
      "walletAddress": "0xWALLET_ADDRESS",
      "signature": "SUPER_SECRET_SIGNATURE",
      "tokenIds": ["TOKEN_ID_1", "TOKEN_ID_2"],
      "minTokenBalance": 1,
      "chain": "solana"
    }'`,
  shell_polygon: getEVMShellSnippet("polygon"),
  shell_avalanche: getEVMShellSnippet("avalanche"),
  shell_flow: getEVMShellSnippet("flow"),
  shell_optimism: getEVMShellSnippet("optimism"),
  shell_arbitrum: getEVMShellSnippet("arbitrum"),
  swift_ethereum: `  // In app delegate
  import Picket
  let picket = Picket(key: "PUBLISHABLE_KEY")

  // In View Controller
  picket.Login(
    contractAddress: "CONTRACT_ADDRESS",
    minTokenBalance: 1
  )`,
  swift_polygon: getEVMSwiftSnippet("polygon"),
  swift_avalanche: getEVMSwiftSnippet("avalanche"),
  swift_solana: `  // In app delegate
  import Picket
  let picket = Picket(key: "PUBLISHABLE_KEY")

  // In View Controller
  picket.Login(
    tokenIds: ["TOKEN_ID_1", "TOKEN_ID_2"],
    minTokenBalance: 1,
    chain: "solana"
  )`,
  swift_flow: getEVMSwiftSnippet("flow"),
  swift_optimism: getEVMSwiftSnippet("optimism"),
  swift_arbitrum: getEVMSwiftSnippet("arbitrum"),
  


  // JS Examples for auth scenarios
  javascript_wallet_auth: `

await picket.login();
  `,
  javascript_token_auth: `

await picket.login({
  contractAddress: "CONTRACT_ADDRESS",
  minTokenBalance: 1
});
  `,
  javascript_allow_list_auth: `

await picket.login({
  allowedWallets: ["ADDRESS_1", "ADDRESS_2"],
});
`,
javascript_imports: `
import Picket from "@picketapi/picket-js";
const picket = new Picket("PUBLISHABLE_KEY");

`
};

export default snippets;
