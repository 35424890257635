import { useState, useEffect, useRef, Fragment } from "react";
import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import classNames from "classnames";
import Link from "next/link";
import Image from "next/image";
import Typed from "typed.js";

import { CodeBlock, tomorrowNightBlue as theme } from "react-code-blocks";
import { ScaleLoader } from "react-spinners";

import codeSnippets from "lib/codeSnippets";
import quotes from "lib/quotes";
import { useInterval } from "lib/hooks";

import styles from "styles/Home.module.css";

import { usePicket } from "@picketapi/picket-react";

const BUTTON_LOADER_SIZE = 12;
const CHAIN_ICON_SIZE = 30;
const WALLET_ICON_SIZE = 14;
const ROTATE_CHANGE_INTERVAL = 4000;
const QUOTE_IMG_SIZE = 60;
const DETAIL_OBJECT_IMG_SIZE = 60;

const CHAINS = [
  "ethereum",
  "polygon",
  "avalanche",
  "flow",
  "optimism",
  "arbitrum",
  "solana",
];

type SnippetName = keyof typeof codeSnippets;

const getAccount = async (accessToken: string) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  const resp = await fetch(`/api/internal/accounts/current`, {
    headers,
  });

  // no account. return nothing
  if (resp.status !== 200) {
    return;
  }

  return await resp.json();
};

const Home: NextPage = () => {
  const router = useRouter();

  const [selectedLanguage, setSelectedLanguage] = useState("javascript");
  const [selectedChain, setSelectedChain] = useState("ethereum");
  const selectedSnippetName =
    `${selectedLanguage}_${selectedChain}` as SnippetName;

  const [selectedQuoteId, setSelectedQuoteId] = useState(quotes[0].id);
  const selectedQuote =
    quotes.find((quote) => quote.id === selectedQuoteId) || quotes[0];

  const [selectedAuthOption, setSelectedAuthOption] = useState("walletAuth");

  const [hasAccount, setHasAccount] = useState(false);

  const { isAuthenticating, isAuthenticated, authState, login } = usePicket();

  useInterval(() => {
    const idx = CHAINS.indexOf(selectedChain);
    const nextIdx = (idx + 1) % CHAINS.length;
    setSelectedChain(CHAINS[nextIdx]);
  }, ROTATE_CHANGE_INTERVAL);

  // Create reference to store the DOM element containing the animation
  const audience_lang_typed = useRef<HTMLSpanElement>(null);

  const code_example_typed = useRef(null);
  // Create reference to store the Typed instance itself
  const typed = useRef<Typed | null>(null);

  // on load
  useEffect(() => {
    const options = {
      strings: [
        "audience.",
        "audiencia",
        "جمهور",
        "gehoor",
        "publikum",
        "觀眾",
        "κοινό",
        "spectateurs",
        "kitle",
        "청중",
        "publiek",
        "lucht féachana",
        "קהל",
      ],
      typeSpeed: 60,
      backSpeed: 60,
      loop: true,
    };

    const codeTypingOptions = {
      strings: [codeSnippets["javascript_wallet_auth"]],
      typeSpeed: 60,
      backSpeed: 60,
    };

    // audience_lang_typed refers to the <span> of that name.
    const languageTyper = new Typed(audience_lang_typed.current!, options);
    typed.current = new Typed(code_example_typed.current!, codeTypingOptions);

    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current!.destroy();
      languageTyper.destroy();
    };
  }, []);

  const loginOrSignup = async () => {
    const authState = await login();

    // if user cancels do nothing
    if (!authState) return;

    const { accessToken } = authState;
    const account = await getAccount(accessToken);

    // no account
    if (!account) {
      // redirect to signup flow
      setHasAccount(false);
      router.push("/signup");
      return;
    }

    // redirect to account page
    setHasAccount(true);
    router.push("/dashboard");
  };

  const viewAccount = async () => {
    if (!isAuthenticated || !authState) return;

    if (hasAccount) {
      router.push("/dashboard");
      return;
    }

    // check if account exists
    const { accessToken } = authState;
    const account = await getAccount(accessToken);

    // no account
    if (!account) {
      // redirect to signup flow
      setHasAccount(false);
      router.push("/signup");
      return;
    }

    // redirect to account page
    setHasAccount(true);
    router.push("/dashboard");
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>Picket</title>
      </Head>

      <main>
        <div className={styles.fullContentContainer}>
          <div className={styles.fullLandingArea}>
            <div className={styles.headerContainer}>
              <div className={styles.logoText}>Picket</div>
              <div>
                {!isAuthenticated && (
                  <button
                    className={styles.connectWalletButton}
                    onClick={() => loginOrSignup()}
                    disabled={isAuthenticating}
                  >
                    {isAuthenticating && (
                      <ScaleLoader height={BUTTON_LOADER_SIZE} color={"#999"} />
                    )}
                    {!isAuthenticating && (
                      <span className={styles.walletIcon}>
                        <Image
                          src="/images/WalletIcon.svg"
                          alt="Wallet"
                          width={WALLET_ICON_SIZE}
                          height={WALLET_ICON_SIZE}
                        />
                      </span>
                    )}
                    {!isAuthenticating && <div>Log In with Your Wallet</div>}
                  </button>
                )}
                {isAuthenticated && (
                  <button
                    className={styles.connectWalletButton}
                    onClick={() => viewAccount()}
                  >
                    View Account
                  </button>
                )}
              </div>
            </div>
            <div className={styles.mainContent}>
              <p className={styles.mainHeader}>
                Web3
                <br />
                Authentication
                <br />
                Made Easy
              </p>
              <p className={styles.subHeader}>
                A single API call to authenticate wallets and token gate
                anything.
              </p>
              <div className={styles.chainPicker}>
                <div className={styles.chainImageOption}>
                  <input
                    className={classNames(
                      styles.chainImageOptionButton,
                      styles.chainImageOptionEthereumButton
                    )}
                    type="radio"
                    name="chain"
                    value="ethereum"
                    id="ethereumChainButton"
                    checked={selectedChain === "ethereum"}
                    onChange={() => setSelectedChain("ethereum")}
                  />
                  <label htmlFor="ethereumChainButton">
                    <Image
                      height={CHAIN_ICON_SIZE}
                      width={CHAIN_ICON_SIZE}
                      src="/images/ethereum_grey.png"
                      alt="Ethereum"
                      priority
                    />
                  </label>
                </div>
                <div className={styles.chainImageOption}>
                  <input
                    className={classNames(
                      styles.chainImageOptionButton,
                      styles.chainImageOptionPolygonButton
                    )}
                    type="radio"
                    name="chain"
                    value="polygon"
                    id="polygonChainButton"
                    checked={selectedChain === "polygon"}
                    onChange={() => setSelectedChain("polygon")}
                  />
                  <label htmlFor="polygonChainButton">
                    <Image
                      height={CHAIN_ICON_SIZE}
                      width={CHAIN_ICON_SIZE}
                      src="/images/polygon_grey.png"
                      alt="Polygon"
                      priority
                    />
                  </label>
                </div>
                <div className={styles.chainImageOption}>
                  <input
                    className={classNames(
                      styles.chainImageOptionButton,
                      styles.chainImageOptionAvalancheButton
                    )}
                    type="radio"
                    name="chain"
                    value="avalanche"
                    id="avalancheChainButton"
                    checked={selectedChain === "avalanche"}
                    onChange={() => setSelectedChain("avalanche")}
                  />
                  <label htmlFor="avalancheChainButton">
                    <Image
                      height={CHAIN_ICON_SIZE}
                      width={CHAIN_ICON_SIZE}
                      src="/images/avalanche_grey.png"
                      alt="Avalanche"
                      priority
                    />
                  </label>
                </div>
                <div className={styles.chainImageOption}>
                  <input
                    className={classNames(
                      styles.chainImageOptionButton,
                      styles.chainImageOptionFlowButton
                    )}
                    type="radio"
                    name="chain"
                    value="flow"
                    id="flowChainButton"
                    checked={selectedChain === "flow"}
                    onChange={() => setSelectedChain("flow")}
                  />
                  <label htmlFor="flowChainButton">
                    <Image
                      height={CHAIN_ICON_SIZE}
                      width={CHAIN_ICON_SIZE}
                      src="/images/flow_grey.png"
                      alt="Flow"
                      priority
                    />
                  </label>
                </div>
                <div className={styles.chainImageOption}>
                  <input
                    className={classNames(
                      styles.chainImageOptionButton,
                      styles.chainImageOptionOptimismButton
                    )}
                    type="radio"
                    name="chain"
                    value="optimism"
                    id="optimismChainButton"
                    checked={selectedChain === "optimism"}
                    onChange={() => setSelectedChain("optimism")}
                  />
                  <label htmlFor="optimismChainButton">
                    <Image
                      height={CHAIN_ICON_SIZE}
                      width={CHAIN_ICON_SIZE}
                      src="/images/optimism_grey.png"
                      alt="Optimism"
                      priority
                    />
                  </label>
                </div>
                <div className={styles.chainImageOption}>
                  <input
                    className={classNames(
                      styles.chainImageOptionButton,
                      styles.chainImageOptionArbitrumButton
                    )}
                    type="radio"
                    name="chain"
                    value="arbitrum"
                    id="arbitrumChainButton"
                    checked={selectedChain === "arbitrum"}
                    onChange={() => setSelectedChain("arbitrum")}
                  />
                  <label htmlFor="arbitrumChainButton">
                    <Image
                      height={CHAIN_ICON_SIZE}
                      width={CHAIN_ICON_SIZE}
                      src="/images/arbitrum_grey.png"
                      alt="Arbitrum"
                      priority
                    />
                  </label>
                </div>
                <div className={styles.chainImageOption}>
                  <input
                    className={classNames(
                      styles.chainImageOptionButton,
                      styles.chainImageOptionSolanaButton
                    )}
                    type="radio"
                    name="chain"
                    value="solana"
                    id="solanaChainButton"
                    checked={selectedChain === "solana"}
                    onChange={() => setSelectedChain("solana")}
                  />
                  <label htmlFor="solanaChainButton">
                    <Image
                      height={CHAIN_ICON_SIZE}
                      width={CHAIN_ICON_SIZE}
                      src="/images/solana_grey.png"
                      alt="Solana"
                      priority
                    />
                  </label>
                </div>
              </div>
              <div className={styles.codeBlockContainer}>
                <div className={styles.codeBlockTopBar}>
                  <div id="langOptions" className={styles.langOptions}>
                    <div className={styles.langOption}>
                      <input
                        type="radio"
                        name="lang"
                        value="curl"
                        id="curlLangButton"
                        className={styles.langOption}
                        checked={selectedLanguage === "shell"}
                        onChange={() => setSelectedLanguage("shell")}
                      />
                      <label htmlFor="curlLangButton">curl</label>
                    </div>
                    <div className={styles.langOption}>
                      <input
                        type="radio"
                        name="lang"
                        value="javascript"
                        id="javascriptLangButton"
                        checked={selectedLanguage === "javascript"}
                        onChange={() => setSelectedLanguage("javascript")}
                      />
                      <label htmlFor="javascriptLangButton">javascript</label>
                    </div>
                    <div className={styles.langOption}>
                      <input
                        type="radio"
                        name="lang"
                        value="swift"
                        id="swiftLangButton"
                        checked={selectedLanguage === "swift"}
                        onChange={() => {
                          setSelectedLanguage("swift");
                        }}
                      />
                      <label htmlFor="swiftLangButton">swift</label>
                    </div>
                  </div>
                </div>
                <div className={styles.codeBlock}>
                  <CodeBlock
                    text={codeSnippets[selectedSnippetName]}
                    language="javascript"
                    showLineNumbers={false}
                    theme={theme}
                    customStyle={{
                      borderRadius: "0px",
                      padding: "10px",
                      fontFamily: "menlo",
                    }}
                  />
                </div>
              </div>

              <div className={styles.ctaButtons}>
                <a
                  target="_blank"
                  href="https://docs.picketapi.com"
                  rel="noopener noreferrer"
                  className={styles.landingCTAButton}
                >
                  Read The Docs
                </a>
                <Link href="/signup" passHref>
                  <a
                    className={classNames(
                      styles.landingCTAButton,
                      styles.earlyAccessButton
                    )}
                  >
                    Get Started
                  </a>
                </Link>
              </div>

              <p className={styles.longTextCTABlack}>
                Want free integration help? Have a question?{" "}
                <span className={styles.longTextCTABlackLink}>
                  <a href="mailto:team@picketapi.com"> Contact us</a>
                </span>
              </p>
            </div>
          </div>
          <div
            className={classNames(styles.modalSection, styles.homePageSection)}
          >
            <div className={styles.sectionContent}>
              <div className={styles.sectionHeaderContainer}>
                <p className={styles.sectionTitle}>Optimized for conversion</p>
                <p className={styles.sectionHeader}>
                  Maximize conversions with an optimized login flow
                </p>
                <p className={styles.sectionSubHeader}>
                  Leverage Picket&apos;s multichain modal that is constantly
                  learning and improving to convert more users.
                </p>
              </div>
              <div className={styles.modalSectionDetailContent}>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className={styles.modalVideo}
                >
                  <source
                    src="/videos/picket_modal_login_video_v7.mp4"
                    type="video/mp4"
                  />
                </video>
                <div className={styles.rightDetailsContainer}>
                  <div className={styles.bigStatObjectsContainer}>
                    <div className={styles.bigStatObject}>
                      <p className={styles.bigStatTitle}>Convert more users</p>
                      <p className={styles.bigStat}>54%</p>
                      <p className={styles.bigStatText}>
                        Increase in conversion rate from using picket.
                      </p>
                    </div>
                    <div className={styles.bigStatObject}>
                      <p className={styles.bigStatTitle}>Save Time</p>
                      <p className={styles.bigStat}>
                        {" "}
                        <span className={styles.bigStatLessThanSign}>&lt;</span>
                        10
                        <span className={styles.bigStatSmallText}>minutes</span>
                      </p>
                      <p className={styles.bigStatText}>
                        to authenticate your first user.
                      </p>
                    </div>
                  </div>
                  <p className={styles.longTextCTAPurple}>
                    <a href="https://picketapi.com/signup">
                      Start maximizing login conversion in under 10 minutes{" "}
                      <span>&rarr;</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.authFeatureSection,
              styles.homePageSection
            )}
          >
            <div className={styles.sectionContent}>
              <div className={styles.sectionHeaderContainer}>
                <p className={styles.sectionTitle}>
                  Your new web3 super powers
                </p>
                <p className={styles.sectionHeader}>
                  Web3 first access control
                </p>
                <p className={styles.sectionSubHeader}>
                  One API for a variety of web3 access control methodologies.
                </p>
              </div>
              <div className={styles.authFeatureSectionDetailContent}>
                <div className={styles.authFeatureList}>
                  <input
                    className={classNames(styles.authOptionButton)}
                    type="radio"
                    name="authOption"
                    value="walletAuth"
                    id="walletAuthButton"
                    checked={selectedAuthOption === "walletAuth"}
                    onChange={() => {
                      setSelectedAuthOption("walletAuth");
                      typed.current?.destroy();

                      const codeTypingOptions = {
                        strings: [codeSnippets["javascript_wallet_auth"]],
                        typeSpeed: 60,
                        backSpeed: 60,
                      };
                      typed.current = new Typed(
                        code_example_typed.current!,
                        codeTypingOptions
                      );
                    }}
                  />
                  <label htmlFor="walletAuthButton">
                    <div className={styles.authFeatureOption}>
                      <div className={styles.authFeatureOptionHeader}>
                        <div className={styles.authFeatureImageContainer}>
                          <Image
                            className={styles.authFeatureImage}
                            src="/images/wallet_auth_icon.png"
                            alt="Wallet Auth"
                            width={DETAIL_OBJECT_IMG_SIZE}
                            height={DETAIL_OBJECT_IMG_SIZE}
                          />
                        </div>
                        <p className={styles.authFeatureHeaderText}>
                          Wallet Authentication
                        </p>
                      </div>
                      {selectedAuthOption === "walletAuth" && (
                        <p className={styles.authFeatureDetailText}>
                          Validate that users own a given wallet. Use this to
                          log in users with their wallet, link users&apos;
                          wallets to existing user accounts and more.
                        </p>
                      )}
                    </div>
                  </label>
                  <input
                    className={classNames(styles.authOptionButton)}
                    type="radio"
                    name="authOption"
                    value="tokenAuth"
                    id="tokenAuthButton"
                    checked={selectedAuthOption === "tokenAuth"}
                    onChange={() => {
                      setSelectedAuthOption("tokenAuth");
                      typed.current?.destroy();

                      const codeTypingOptions = {
                        strings: [codeSnippets["javascript_token_auth"]],
                        typeSpeed: 60,
                        backSpeed: 60,
                      };
                      typed.current = new Typed(
                        code_example_typed.current!,
                        codeTypingOptions
                      );
                    }}
                  />
                  <label htmlFor="tokenAuthButton">
                    <div className={styles.authFeatureOption}>
                      <div className={styles.authFeatureOptionHeader}>
                        <div className={styles.authFeatureImageContainer}>
                          <Image
                            className={styles.authFeatureImage}
                            src="/images/token_auth_icon.png"
                            alt="Tools for every stack"
                            width={DETAIL_OBJECT_IMG_SIZE}
                            height={DETAIL_OBJECT_IMG_SIZE}
                          />
                        </div>
                        <p className={styles.authFeatureHeaderText}>
                          Token Based Authorization
                        </p>
                      </div>
                      {selectedAuthOption === "tokenAuth" && (
                        <p className={styles.authFeatureDetailText}>
                          Limit access to users based on the their wallet
                          holdings. Support for ERC-20, ERC-721, SPL tokens and
                          more.
                        </p>
                      )}
                    </div>
                  </label>
                  <input
                    className={classNames(styles.authOptionButton)}
                    type="radio"
                    name="authOption"
                    value="allowListAuth"
                    id="allowListAuthButton"
                    checked={selectedAuthOption === "allowListAuth"}
                    onChange={() => {
                      setSelectedAuthOption("allowListAuth");
                      typed.current?.destroy();

                      const codeTypingOptions = {
                        strings: [codeSnippets["javascript_allow_list_auth"]],
                        typeSpeed: 60,
                        backSpeed: 60,
                      };
                      typed.current = new Typed(
                        code_example_typed.current!,
                        codeTypingOptions
                      );
                    }}
                  />
                  <label htmlFor="allowListAuthButton">
                    <div className={styles.authFeatureOption}>
                      <div className={styles.authFeatureOptionHeader}>
                        <div className={styles.authFeatureImageContainer}>
                          <Image
                            className={styles.authFeatureImage}
                            src="/images/allow_list_icon.png"
                            alt="Tools for every stack"
                            width={DETAIL_OBJECT_IMG_SIZE}
                            height={DETAIL_OBJECT_IMG_SIZE}
                          />
                        </div>
                        <p className={styles.authFeatureHeaderText}>
                          Wallet Allow Lists
                        </p>
                      </div>
                      {selectedAuthOption === "allowListAuth" && (
                        <p className={styles.authFeatureDetailText}>
                          Limit access to users based on their wallet address or
                          ENS name. Enable your users to create lists of wallets
                          with access and more.
                        </p>
                      )}
                    </div>
                  </label>
                </div>
                <div className={styles.exampleCodeBlockAndCTA}>
                  <pre className={styles.exampleCodeBlock}>
                    {codeSnippets["javascript_imports"]}
                    <span
                      className={styles.exampleCodeSpan}
                      ref={code_example_typed}
                    ></span>
                  </pre>
                  <p className={styles.longTextCTAPurple}>
                    <a href="https://picketapi.com/signup">
                      Start authenticating users in &lt;10min &rarr;
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(styles.apiSection, styles.homePageSection)}
          >
            <div className={styles.sectionContent}>
              <div className={styles.sectionHeaderContainer}>
                <p className={styles.sectionTitle}>
                  Build exactly as you imagine, only faster.
                </p>
                <p className={styles.darkSectionHeader}>
                  Powerful APIs for entirely custom front ends
                </p>
                <p className={styles.darkSectionSubHeader}>
                  Go beyond login. Picket&apos;s RESTful APIs can be used with
                  or without the login modal. Create entirely unique wallet
                  authentication and token gating experiences with Picket&apos;s
                  fully headless API set.
                </p>
              </div>
              <div className={styles.apiSectionDetailContent}>
                <div className={styles.apiCallToActionSection}>
                  <a
                    target="_blank"
                    href="https://docs.picketapi.com"
                    rel="noopener noreferrer"
                    className={styles.smallCTAButton}
                  >
                    Read The Docs
                  </a>
                  <p className={styles.longTextCTA}>
                    Want free 1:1 integration help?{" "}
                    <br className={styles.officeHoursLinkBreak} />
                    <a
                      className={styles.longTextLink}
                      href="https://picketapi.com/officehours"
                    >
                      Join Office Hours
                    </a>
                  </p>
                </div>
                <div className={styles.horizontalDetailObjects}>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectImageContainer}>
                      <Image
                        className={styles.detailObjectImage}
                        src="/images/terminal_icon.png"
                        alt="Tools for every stack"
                        width={DETAIL_OBJECT_IMG_SIZE}
                        height={DETAIL_OBJECT_IMG_SIZE}
                      />
                    </div>
                    <p className={styles.detailObjectHeader}>
                      Tools for every stack
                    </p>
                    <p className={styles.detailObjectBody}>
                      We offer client and server libraries in everything from
                      React and Node to Python and iOS
                    </p>
                    <a
                      className={styles.detailObjectCTALink}
                      href="https://docs.picketapi.com/picket-docs/reference/libraries-and-sdks"
                    >
                      See Libraries &#10132;
                    </a>
                  </div>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectImageContainer}>
                      <Image
                        className={styles.detailObjectImage}
                        src="/images/apps_icon.png"
                        alt="Prebuilt Integrations"
                        width={DETAIL_OBJECT_IMG_SIZE}
                        height={DETAIL_OBJECT_IMG_SIZE}
                      />
                    </div>
                    <p className={styles.detailObjectHeader}>
                      Prebuilt integrations
                    </p>
                    <p className={styles.detailObjectBody}>
                      Use integrations for services like Shopify, BigCommerce
                      and AWS Cognito.
                    </p>
                    <a
                      className={styles.detailObjectCTALink}
                      href="https://docs.picketapi.com/picket-docs/reference/integrations"
                    >
                      Explore Partners &#10132;
                    </a>
                  </div>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectImageContainer}>
                      <Image
                        className={styles.detailObjectImage}
                        src="/images/ethereum_hand_icon.png"
                        alt="Support for every chain"
                        width={DETAIL_OBJECT_IMG_SIZE}
                        height={DETAIL_OBJECT_IMG_SIZE}
                      />
                    </div>
                    <p className={styles.detailObjectHeader}>
                      Support for every chain
                    </p>
                    <p className={styles.detailObjectBody}>
                      Picket APIs work across all major blockchains. Including
                      Ethereum, Solana, Flow and more.
                    </p>
                    <a
                      className={styles.detailObjectCTALink}
                      href="https://docs.picketapi.com/picket-docs/reference/concepts/supported-blockchains"
                    >
                      View Supported Chains &#10132;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(styles.whySection, styles.homePageSection)}
          >
            <div className={styles.sectionContent}>
              <div className={styles.sectionHeaderContainer}>
                <p className={styles.sectionTitle}>Why Picket</p>
                <p className={styles.sectionHeader}>
                  A developer first approach to Web3
                </p>
              </div>
              <div className={styles.apiSectionDetailContent}>
                <div
                  className={classNames(
                    styles.horizontalDetailObjects,
                    styles.whyHorizontalDetailSection
                  )}
                >
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectImageContainer}>
                      <Image
                        className={styles.detailObjectImage}
                        src="/images/terminal_icon_purple.png"
                        alt="API First"
                        width={DETAIL_OBJECT_IMG_SIZE}
                        height={DETAIL_OBJECT_IMG_SIZE}
                      />
                    </div>
                    <p className={styles.detailObjectHeaderLightBG}>
                      API First
                    </p>
                    <p className={styles.detailObjectBodyLightBG}>
                      All features can be controlled via our headless API. No
                      need to mess with graphical dashboards when you want full
                      programmatic control.
                    </p>
                  </div>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectImageContainer}>
                      <Image
                        className={styles.detailObjectImage}
                        src="/images/shield_icon.png"
                        alt="Security Handled"
                        width={DETAIL_OBJECT_IMG_SIZE}
                        height={DETAIL_OBJECT_IMG_SIZE}
                      />
                    </div>
                    <p className={styles.detailObjectHeaderLightBG}>
                      Security Handled
                    </p>
                    <p className={styles.detailObjectBodyLightBG}>
                      We obsess over security so you don&apos;t have to. Stay up
                      to date with industry standards and gain peace of mind.
                    </p>
                  </div>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectImageContainer}>
                      <Image
                        className={styles.detailObjectImage}
                        src="/images/headset_icon.png"
                        alt="Premium Support"
                        width={DETAIL_OBJECT_IMG_SIZE}
                        height={DETAIL_OBJECT_IMG_SIZE}
                      />
                    </div>
                    <p className={styles.detailObjectHeaderLightBG}>
                      24/7 Premium Support
                    </p>
                    <p className={styles.detailObjectBodyLightBG}>
                      We offer 24/7 premium email support as well as Zoom office
                      hours with members of our engineering team for help with
                      your integration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(styles.scaleSection, styles.homePageSection)}
          >
            <div className={styles.sectionContent}>
              <div className={styles.sectionHeaderContainer}>
                <p className={styles.sectionTitle}>Globe-ready</p>
                <p
                  className={classNames(
                    styles.darkSectionHeader,
                    styles.sectionHeaderWithTyping
                  )}
                >
                  Earth scale services built for a global{" "}
                  <br className={styles.breakForLanguageTyper} />
                  <span
                    className={styles.typedLanguageSpan}
                    ref={audience_lang_typed}
                  />
                </p>
                <p className={styles.darkSectionSubHeader}>
                  For ambitious teams looking to serve the world, Picket makes
                  sure your tools never hold you back. With Picket, you&apos;re
                  ready to serve a global audience from day 0. Crypto is global,
                  it&apos;s time your tools were up to the task.
                </p>
              </div>
              <div className={styles.apiSectionDetailContent}>
                <div className={styles.horizontalDetailObjects}>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectTextContainer}>
                      <div className={styles.bigNumberContainer}>
                        <p className={styles.bigNumberText}>100+</p>
                        <p className={styles.smallLabelForBigNumber}>
                          {" "}
                          languages
                        </p>
                      </div>
                    </div>
                    <p className={styles.detailObjectHeader}>Every language</p>
                    <p className={styles.detailObjectBody}>
                      The Picket modal enables you to serve a global audience
                      from day 0 with automatic localization into 100+
                      languages.
                    </p>
                  </div>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectTextContainer}>
                      <div className={styles.bigNumberContainer}>
                        <p className={styles.bigNumberText}>99.99</p>
                        <p className={styles.smallLabelForBigNumber}>
                          {" "}
                          % uptime
                        </p>
                      </div>
                    </div>
                    <p className={styles.detailObjectHeader}>Every Hour</p>
                    <p className={styles.detailObjectBody}>
                      Don&apos;t lose customers to down time again. We work hard
                      day and night to provide you with the best reliability in
                      the industry.
                    </p>
                  </div>
                  <div className={styles.detailObject}>
                    <div className={styles.detailObjectTextContainer}>
                      <div className={styles.bigNumberContainer}>
                        <p className={styles.bigNumberText}>170+</p>
                        <p className={styles.smallLabelForBigNumber}>
                          {" "}
                          wallets
                        </p>
                      </div>
                    </div>
                    <p className={styles.detailObjectHeader}>Every Wallet</p>
                    <p className={styles.detailObjectBody}>
                      Different markets prefer different wallets. Support them
                      all with one integration. When new wallets hit the market
                      you&apos;ll be among the first to support them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.quoteCarouselSection,
              styles.homePageSection
            )}
          >
            <p className={styles.miniHeader}>QUOTES FROM THE COMMUNITY</p>
            <div className={styles.quoteCarouselContainer}>
              <div className={styles.quoteCarouselImageContainer}>
                <Image
                  className={styles.quoteCarouselImage}
                  src={selectedQuote.image}
                  alt={selectedQuote.id}
                  width={QUOTE_IMG_SIZE}
                  height={QUOTE_IMG_SIZE}
                />
              </div>
              <div className={styles.quoteContainer}>
                <div className={styles.quoteText}>{selectedQuote.quote}</div>
              </div>
            </div>
            <div className={styles.quotePicker}>
              {quotes.map((quote) => (
                <Fragment key={quote.id}>
                  <input
                    className={classNames(styles.quoteOptionButton)}
                    type="radio"
                    name={`quote-${quote.id}`}
                    value={quote.id}
                    id={`quote-${quote.id}`}
                    checked={selectedQuoteId === quote.id}
                    onChange={() => {
                      setSelectedQuoteId(quote.id);
                    }}
                  />
                  <label
                    className={styles.dotButton}
                    htmlFor={`quote-${quote.id}`}
                  ></label>
                </Fragment>
              ))}
            </div>
          </div>
          <div className={styles.bottomSection}>
            <div className={styles.linkListSections}>
              <div className={styles.bottomSectionLeftList}>
                <p className={styles.linkListHeader}>Use Cases</p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs#one-click-wallet-authentication"
                >
                  <p className={styles.linkListElement}>Wallet Login</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs#token-gating"
                >
                  <p className={styles.linkListElement}>Token Gating</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs#linking-a-wallet-to-an-existing-account"
                >
                  <p className={styles.linkListElement}>
                    Link Wallets to Users
                  </p>
                </a>

                <span className={styles.copyrightNotice}>
                  © Picket Labs LLC
                </span>
              </div>
              <div className={styles.bottomSectionMiddleList}>
                <p className={styles.linkListHeader}>Developers</p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs/"
                >
                  <p className={styles.linkListElement}>Overview</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs/quick-start-guides/quick-start-guides"
                >
                  <p className={styles.linkListElement}>Quick Start Guides</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs/reference/concepts"
                >
                  <p className={styles.linkListElement}>Concepts</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs/reference/api-reference"
                >
                  <p className={styles.linkListElement}>API Reference</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs/reference/libraries-and-sdks"
                >
                  <p className={styles.linkListElement}>Libraries & SDKs</p>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.picketapi.com/picket-docs/tutorials/login-with-wallet-example-javascript-library"
                >
                  <p className={styles.linkListElement}>Examples</p>
                </a>
              </div>
              <div className={styles.bottomSectionRightList}>
                <p className={styles.linkListHeader}>Resources</p>
                <Link href="/support" passHref>
                  <p className={styles.linkListElement}>Support</p>
                </Link>

                {/* TODO: Complete underlying resources and uncomment below */}
                <Link href="/officehours" passHref>
                  <p className={styles.linkListElement}>Office Hours</p>
                </Link>
                {/* <Link href="/legal" passHref><p className={styles.linkListElement}>Privacy and Terms</p></Link> */}

                <Link href="/privacy" passHref>
                  <p className={styles.linkListElement}>Privacy</p>
                </Link>

                <Link href="/terms" passHref>
                  <p className={styles.linkListElement}>Terms</p>
                </Link>

                <Link href="/pricing" passHref>
                  <p className={styles.linkListElement}>Pricing</p>
                </Link>

                <span className={styles.emailAddress}>
                  <a href="mailto:team@picketapi.com">team@picketapi.com</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Home;
