const quotes = [{
    id: "alchemy",
    quote: `“Picket's developer experience is exceptional... We believe Picket will help Alchemy's developers build their web3 experiences even faster, accelerate the future of wallet-based identity, and become the standard solution for web3 IAM.”

-Alchemy`,
    image: "/images/alchemy_quote_logo.png",
},{
    id: "bigcommerce",
    quote: `“What I like about Picket is that their product is really simple (as a user) and people less familiar with Web3 can understand and implement it.”

    - Katie Hoesley, BigCommerce`,
    image: "/images/bigcommerce_quote_logo.png",
},{
    id: "independent_dev",
    quote: `“I'm already In love with this SDK. Much more straightforward than alternatives like Moralis or Thirdweb which I previously used.”

    - Independent Developer`,
    image: "/images/person_quote_logo.png",
},{
    id: "japan_drops",
    quote: `“This is great! Was super easy to implement, thanks so much for putting it together.”

- Engineer at Japan Drops`,
    image: "/images/japan_drops_quote_logo.png",
}];
  
  export default quotes;
  